import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import GlobalStyle from '../../__Global.style';
import Panda from 'imgs/zj/pandas.jpg';

const Main = styled.main`
  max-width: 40rem;
  background: white;
  margin: 10rem auto 0;
  text-align:center;
  position:relative;
  z-index:3
`;
const GuideL2 = () => {
    return (
        <Main>
            <Helmet title='心力UP训练营L2家长指导' />
            <GlobalStyle />
            <img src={Panda} alt="" style={{width:'100%',height:'100%',position:'fixed',top:0,left:0,zIndex:'-1'}}/>
            <video
             src='http://contents01.zijing365.com.cn/zijing/yunke/xinli/xinliup2.mp4'
             controls={true}
             loop
             style={{
                maxWidth:'40rem',
                width:'100%',
                marginTop:'12rem'
             }}
             />
        </Main>

    );
};

export default GuideL2;